import React from 'react';

import { Container, Form, Button, Image } from 'react-bootstrap';

import {
  PlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
} from 'react-plaid-link';

import { PlaidAPI } from '../../api/plaidAPI';

// Importing custom css
import '../signinComponent/signin.css';

// Importing logo
import logo from "../../assets/images/CLG_Logo.png";

// Importing success icon
//import success from '../../assets/images/success.gif';
import Lottie from 'lottie-react';
import success from '../../assets/success.json';

interface Props {
  clientID: string;
  name: string;
}

interface State {
  token: null;
  success: boolean;
}

class PlaidLinkClass extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { 
      token: null,
      success: false
    };
  }
  
  /**
   * Function that calls the server to acquire a link token to access Link for the client
   */
  createLinkToken() {
    //API call to get Link Token
    PlaidAPI.get_link_token().then((response) => {

        const link_token = response.data.link_token;
        this.setState({ token: link_token });

        //console.log("Link Token: " + link_token);
    }).catch((error) => {
        console.log(error);
    });
  }

  /**
   * Function that calls the server to exchange a public token for a permanent access token
   */
  exchangeToken(public_token: string) {
    //API call to get Link Token
    PlaidAPI.exchange_token(public_token, this.props.clientID).then((response) => {

        //Print out props
        //console.log("ClientID : " + this.props.clientID);
        //console.log("ApiKey: " + this.props.apiKey);
        console.log(response.data);

        if (response.status === 200) {
          //console.log("Success");
          this.setState({ success: true });
        }
    }).catch((error) => {
        console.log(error);
    });
  }

  async componentDidMount() {
    this.createLinkToken();
  }


  onSuccess: PlaidLinkOnSuccess = (publicToken, metadata) => {
    // send public_token to your server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    //console.log("Public Token: " + publicToken);
    
    this.exchangeToken(publicToken);
  };

  onEvent: PlaidLinkOnEvent = (eventName, metadata) => {
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    //console.log(eventName, metadata);
  };

  onExit: PlaidLinkOnExit = (error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    //console.log(error, metadata);
  };

  render() {

    //console.log("Success: " + this.state.success);

    if (this.state.success) {
      return (
        <Container className='container'>
          <div className="main">
            <Image src={logo} className='clg_logo' alt="CLG Logo" />
            <div>
              <Lottie animationData={success} />
            </div>
            <h2>You have successfully linked your bank account with Consumer Legal Group.</h2>
          </div>
        </Container>
      );
    }
    else {
      return (
        <Container className='container'>
          <div className="main">
            <Image src={logo} className='clg_logo' alt="CLG Logo" />
            <h1>{ this.props.clientID === undefined || this.props.clientID === null  ? "No Record Found" : "Welcome " + this.props.name }</h1>
            { this.props.clientID !== undefined && this.props.clientID !== null ?
              <PlaidLink
                className="link_button"
                token={this.state.token}
                onSuccess={this.onSuccess}
                onEvent={this.onEvent}
                onExit={this.onExit}
              >
                Link your bank account
            </PlaidLink> :
            <Button variant="dark" className="button" onClick={() => { window.location.reload() }}>
                Go Back
            </Button>
            }
          </div>
        </Container>
      );
    }
  }
}

export default PlaidLinkClass;

    

   