import React, { useState } from 'react';
import { Container, Form, Button, Image } from 'react-bootstrap';

import PlaidPage from '../plaidComponent/plaidPage';

// Import snowflakeAPI object
import { BackEndAPI } from '../../api/backendAPI';

// Importing bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';

// Importing custom css
import './signin.css';

// Importing logo
import logo from "../../assets/images/CLG_Logo.png";

//SweetAlert
import Swal from 'sweetalert2';

//Phone Input
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

//Birthdate Input
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';


function SignIn() {

    const [nextstep, setNextStep] = useState(false);

    const [clientID, setClientID] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [birthYear, setBirthYear] = useState(1984);
    const [birthMonth, setBirthMonth] = useState(1);
    const [birthDay, setBirthDay] = useState(1);

    const [formData, setFormData] = useState({
        countryCode: 1,
        phoneNumber: '',
    });

    const handleBirthDate = (e) => {
        let input = e.target.value;
        let finalString = input.replace(/\D/g, ''); // Remove non-digits
    
        if (finalString.length > 4) {
            finalString = finalString.slice(0, 4) + '-' + finalString.slice(4);
        }
        if (finalString.length > 2) {
            finalString = finalString.slice(0, 2) + '-' + finalString.slice(2);
        }
    
        setBirthDate(finalString);
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        if (phoneNumber.length !== 11) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Phone Number',
                text: 'Please enter a valid 10 digit phone number!',
            });
            return;
        }

        //Check if the birthdate is valid
        if (birthDate.length !== 10) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Birthdate',
                text: 'Please enter a valid birthdate!',
            });
            return;
        }

        //Get the Year, Month and Day from birthDate
        let year = birthDate.substring(6, 10);
        let month = birthDate.substring(0, 2);
        let day = birthDate.substring(3, 5);

        //API call to SnowFlakeAPI
        BackEndAPI.findUser(phoneNumber, year, month, day).then((response) => {

            const { clientID, firstName, lastName } = response.data;

            setClientID(clientID);
            setName(firstName + " " + lastName);

            //console.log(response.data);

            //Phone Number has been recorded
            setNextStep(true);

        }).catch((error) => {
            console.log(error);

            Swal.fire({
                icon: 'error',
                title: 'Invalid Phone Number, Birthdate, or Database Error',
                text: 'The provided phone number or birthdate does not match our records. Please verify the information and contact Consumer Legal Group if both Phone Number and Birthdate have been verified to be correct.',
            });
        });

    };

    /** User has entered their phone number */
    if (nextstep) {

        //console.log("ClientID: " + clientID);

        return (
            <PlaidPage clientID={clientID} name={name}/>
        );
    }
    else {
        return (
                <Container className='container'>
                    <div className="main">
                        <Image src={logo} className='clg_logo' alt="CLG Logo" />
                        {/*<h4>Payment Support Portal</h4>*/}
                        <Form className='form' onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicText">
                                <Form.Label className="labelText">Please enter the phone number and birthday associated with your account to begin securely linking your bank account.</Form.Label>
                                <PhoneInput
                                    country={'us'}
                                    onlyCountries={['us']}
                                    countryCodeEditable={false}
                                    placeholder="+1 (000) 000-0000"
                                    value={phoneNumber}
                                    inputStyle={{width: '100%'}}
                                    onChange={phone => setPhoneNumber(phone)}
                                />
                                <Form.Label className="labelText">Your Birthdate:</Form.Label>
                                {/*<DatePicker inputStyle={{width: '100%'}} className="birthdaypicker" value={birthDate} onChange={(date) => setBirthDate(date)} />*/}
                                {/*<Form.Label className="labelText">Last name</Form.Label>
                                <Form.Control type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} placeholder="Enter your last name" />*/}
                                <Form.Control type="text" name="birthdate" value={birthDate} onChange={(e) => handleBirthDate(e)} placeholder="MM-DD-YYYY" className="birthdate-input"/>
                            </Form.Group>
                            <Button variant="dark" className="button" type="submit">
                                Continue
                            </Button>
                        </Form>
                    </div>
                </Container>
        );
    }
}

export default SignIn;